import React from 'react'

function MedicalCoding() {
  return (
    <div className='carousal-session2-1'>
        <h1>Medical Coding</h1>
        <p>Is the process of translating healthcare diagnoses, procedures, services, and equipment into standardized codes. These codes are essential for accurately billing insurance companies, maintaining medical records, and ensuring proper reimbursement for healthcare providers.</p>
    </div>
  )
}

export default MedicalCoding