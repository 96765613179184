import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

import { ImSkype } from "react-icons/im";
import { IoCall } from "react-icons/io5";
import { MdLocationPin } from "react-icons/md";
import { RiWhatsappFill } from "react-icons/ri";
import { SiGmail } from "react-icons/si";
import { IoLogoInstagram } from "react-icons/io5";
import { AiFillInstagram } from "react-icons/ai";
import logo from '../icons/logo.png';
function Footer() {

  return (
    <div style={{backgroundColor:'#367588'}}>
            <div className='footer'>
        <section className='footer_section1'>
                <img src={logo} alt="" />
                <p style={{fontSize:'12px',textAlign:'justify', color:'white',margin:'2% 3% 2% 1%'}} >Avanza BPO LLC is a Miami-based Business Process Outsourcing (BPO) company specializing in healthcare. We provide innovative solutions to optimize healthcare operations, ensure regulatory compliance, and improve business performance. Our expert team uses advanced technology and industry expertise to streamline workflows, manage resources, and offer data-driven insights that drive growth. Committed to excellence, we partner with healthcare providers to help them achieve operational efficiency and success.</p>
                <Link to="/whoweare" onClick={() => window.scrollTo(0, 0)}><button className='det_but'>View more</button></Link> 
        </section>
        <section className='footer_section2'>
            <div className='Whoweare'>
                <h1 style={{color:'white',marginTop:'5%',marginLeft:'5%'}}>Who We Are <hr style={{width:'100px',}}  /></h1>
                <ul>
                    <Link to="/whoweare#AboutUs"><li className='topics'>About Us</li></Link>
                    <Link to="/whoweare#mission"><li className='topics'>Our Mission</li></Link>
                    <Link to="/whoweare#vission"><li className='topics'>Our vission</li></Link>
                    <Link to="/whoweare#KeyAreas"><li className='topics'>Several Key Areas</li></Link>
                    <Link to="/whoweare#Analytics"><li className='topics'>Enhancing Healthcare Efficiency</li></Link>
                    <Link to="/whoweare#download"><li className='topics'>Download company Brochure</li></Link>
                </ul>
            </div>
            
            <div className='footer_section2-1'>
            <h1 style={{color:'white',marginTop:'5%',marginLeft:'5%'}}>What We Offer<hr style={{width:'100px',}} /></h1>
                <ul>
                    <Link to ="/whatweoffer/ConsultingPackage"><li className='topics'>Consulting Package with Detailed Training Guide</li></Link>
                    <Link to ="/whatweoffer/PersonalizedTrainning"><li className='topics'>Personalized Training for Operations Staff</li></Link>
                    <Link to ="/whatweoffer/Revenue-Cycle-Management"><li className='topics'>RCM - Solution</li></Link>
                    <Link to ="/whatweoffer/MedicalCoding-and-MedicalBilling"><li className='topics'>Medical Coding and Billing</li></Link>
                    <Link to ="/whatweoffer/Affiliated-Services"><li className='topics'>Affiliated Services</li></Link>
                    <Link to ="/whatweoffer/Accounts Receivable (AR) Follow-Up"><li className='topics'>Accounts Receivable (AR) Follow-Up</li></Link>
                    <Link to ="/whatweoffer/DanielManage"><li className='topics'>Denial Management</li></Link>
                </ul>
            </div>
        </section>
        <section className='footer_section3'>
 
            <div className='Specialities'>
            <h1 style={{color:'white',marginTop:'5%',marginLeft:'5%'}}>Specialities<hr style={{width:'100px',}} /></h1>
                <ul>
                    <a href="/specialities"><li>Internal Medicine</li></a>
                    <a href="/specialities"><li>Mental Health</li></a>
                    <a href="/specialities"><li>Orthopedics</li></a>
                    <a href="/specialities"><li>Cardiology</li></a>
                    <a href="/specialities"><li>Dermatology</li></a>
                    <a href="/specialities"><li>Gastroenterology</li></a>
                    <a href="/specialities"><li>Urology</li></a>
                    <a href="/specialities"><li>Endocrinology</li></a>
                    <a href="/specialities"><li>Rheumatology</li></a>
                    <a href="/specialities"><li>Oncology</li></a>
                    <a href="/specialities"><li>Pulmonology</li></a>
                    <a href="/specialities"><li>Neurology</li></a>
                    <a href="/specialities"><li>Radiology</li></a>
                    <a href="/specialities"><li>Chiropractic</li></a>
                    <a href="/specialities"><li>Psychiatry</li></a>
                    <a href="/specialities"><li>Urgent Care</li></a>
                    
                </ul>
            </div>
        </section>
        <section className='footer_section4'>
        <h1 className='contactus-details'>Contact Us<hr style={{width:'100px',}} /></h1>
            <div style={{margin:'5% 0 0 10%'}}>
                <p><MdLocationPin style={{fill:'white'}}/> USA</p>
                <p style={{marginLeft:'30px'}}>Avanza BPO LLC <br /> 601 Brickell Key Drive, Suite700 <br /> Miami Fl 33131
</p>
            </div>
            <div style={{margin:'5% 0 0 10%'}}>
            <p><MdLocationPin style={{fill:'white'}}/> INDIA</p>
            <p style={{marginLeft:'30px'}}>Keelkattalai, Chennai - 600 117</p>
            <p style={{marginTop:'5%'}}><IoCall style={{marginRight:'10px',fill:'white'}}/><a href="tel:13057732239">305-773-2239</a></p>

            <p style={{marginTop:'5%'}}><SiGmail style={{marginRight:'10px',fill:'white'}}/><a href="/contactus">customerservice@avanzasbpo.com</a></p>
            </div>
            
            
            
            <div style={{margin:'10% 0 0 10%'}}  className='footer-connection'>
                <h1 style={{textAlign:'center'}}>Connect with us<hr style={{width:'100px'}} /></h1>
                <div className='footer-icons'>
                   <a href="https://wa.me/13057732239">< RiWhatsappFill size={40} style={{ fill: 'white'}}/></a> 
                   <a href="https://www.instagram.com/avanzabpo_healthcare_services/profilecard/?igsh=djlwY2hvcXZyNWM4"><AiFillInstagram size={40} style={{ fill: 'white'}}/></a> 
                   <a href="https://join.skype.com/invite/nWwpr2iwShZ8">< ImSkype size={40} style={{ fill: 'white'}}/></a> 
                </div>
            </div>
        </section>
    </div>
        <hr />
        <div className='footer-bottom' style={{display:'flex', justifyContent:'end'}}>
        <p ><IoCall style={{marginRight:'10px',fill:'white'}}/><a style={{color:'white'}} href="tel:13057732239">305-773-2239</a></p>
        <p ><SiGmail style={{marginRight:'10px',fill:'white'}}/><a style={{color:'white'}} href="/contactus">customerservice@avanzasbpo.com</a></p>
        </div>

    </div>
  )
}

export default Footer