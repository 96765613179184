import React from 'react'
import ContForm from '../../contactform-component/ContForm'

function PersonalizedTrainning() {
  return (
    <div>
    <div className='whatwedo-banner'>
        <h1>Personalized Training for Operations Staff</h1>
      </div>
      {/* ----------------------------------------- section 1 discription and short explanation ---------------------------------------------------- */}
    <div className='whatwedo-dis'>
      <div className='whatwedo-dis-sec1-1'>
        <div className='wwd-dis-heading'>
          <hr style={{width:'10%', height:'3px', backgroundColor:'#cf7531',marginTop:'5%'}}/>
          <h3 style={{marginLeft:'2%',fontSize:'30px',fontWeight:'600',color:'#cf7531'}}>What We Offer</h3>
        </div>
        <h2 style={{color:'#083b4a',fontWeight:'600',width:'90%',marginTop:'2%'}}>Your Path to Peak Performance Starts Here – Explore the Possibilities</h2>
      </div>
      <div className='whatwedo-dis-sec1-2'>
        <p style={{marginTop:'5%',textAlign:'justify'}}>Welcome to Avanza BPO LLC, where our mission is to empower healthcare practices with comprehensive solutions that drive efficiency, excellence., and enhance the overall health of healthcare providers’ business. Led by our founder, Leidy Arguelles, our consulting package is designed to transform and elevate your healthcare business.</p>
      </div>
    </div>
    
    <div style={{width:'100%',display:'flex',justifyContent:'center',marginTop:'5%'}}>
      <div className='offer-content' style={{display:'flex'}}>
        <div className='consulting-left-session'>
          <a href="#Initial Assessment"><button className="consulting-left-session-btn-1">Initial Assessment</button></a>
          <a href="#Flexible Training Options"><button className="consulting-left-session-btn-2">Flexible Training Options</button></a>
          <a href="#Customized Curriculum"><button className="consulting-left-session-btn-3">Customized Curriculum</button></a>
          <a href="#Ongoing Support"><button className="consulting-left-session-btn-4">Ongoing Support & Continuous Improvement</button></a>
  
        </div>
        <div className='consulting-right-session'>
        <h1 className='consulting-right-session-title' >Personalized Training for Operations Staff</h1>
        <div className='consulting-right-session-content' style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p  className='consulting-right-session-content-title' >- Service Description </p>
          <p id="Initial Assessment" className='consulting-right-session-content-para' style={{margin:'0 0 0 3%'}}>Our Personalized Training for Operations Staff is designed to enhance team efficiency and address specific skill gaps.</p>
        </div>
        <hr />
        <div className='consulting-right-session-content' style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p  className='consulting-right-session-content-title' >- Initial Assessment</p>
          <p className='consulting-right-session-content-para' style={{margin:'0 0 0 3%'}}>We begin with an assessment to identify the unique training needs of your operations team, focusing on practice-specific requirements.</p>
        </div>
        <hr  />
        <div className='consulting-right-session-content' style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p  className='consulting-right-session-content-title' >- Flexible Training Options</p>
          <p className='consulting-right-session-content-para' style={{margin:'0 0 0 3%'}}>•	Training can be conducted as one-on-one sessions or in group settings, depending on the size of your team.</p>
          <p className='consulting-right-session-content-para' style={{margin:'0 0 0 3%'}}>•	Sessions are available in person or via Zoom, offering flexibility to suit your preferences.</p>

        </div>
        <hr />
        <div className='consulting-right-session-content' style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p  className='consulting-right-session-content-title' >- Customized Curriculum </p>
          <p className='consulting-right-session-content-para' style={{margin:'0 0 0 3%'}}>Each training program is customized to address the challenges and goals of your organization, ensuring relevance and impact.</p>
        </div>
        <hr />
        <div className='consulting-right-session-content' style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title' >- Ongoing Support & Continuous Improvement </p>
          <p className='consulting-right-session-content-para' style={{margin:'0 0 0 3%'}}>We provide resources and follow-up sessions to reinforce learning and support continuous improvement.</p>
          <p className='consulting-right-session-content-para' style={{margin:'0 0 0 3%'}}>By investing in personalized training, your operations staff will gain valuable skills that lead to improved productivity and a more effective work environment.</p>

        </div>
        </div>

      </div>
    </div>
    <hr style={{marginTop:'3%'}} />
    <div className='whatwedo-dis-sec3'>
      <div className='whatwedo-dis-sec3-1'>
        <div className='wwd-dis-heading'>
          <hr style={{width:'10%', height:'3px', backgroundColor:'#cf7531',marginTop:'5%'}}/>
          <h3 style={{marginLeft:'2%',fontSize:'30px',fontWeight:'600',color:'#cf7531'}}>At Avanza BPO LLC</h3>
        </div>
        <h2 style={{color:'#083b4a',fontWeight:'600',width:'90%',marginTop:'2%'}}>Partner with Us for Excellence in Healthcare Management</h2>
        <p style={{textAlign:'justify'}}>At Avanza BPO LLC, we are committed to delivering solutions that not only meet but exceed your expectations. Partner with us to enhance your practice’s efficiency, compliance, and overall performance. Let’s work together to achieve excellence in healthcare practice management.</p>
      </div>
      <div className='whatwedo-dis-sec3-2'>
        <ContForm />
      </div>
    </div>
    </div>
  )
}

export default PersonalizedTrainning