import React from 'react'
import { useState, useEffect } from 'react'
import FancyCarousel from "react-fancy-circular-carousel";
import 'react-fancy-circular-carousel/FancyCarousel.css';

import { Link } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import './RCM.css'

import ContForm from '../../../contactform-component/ContForm';


function RCM() {




  return (
    <div>
      <div className='whatwedo-banner'>
        <h1>Complete Revenue Cycle Management Solution</h1>
      </div>
      {/* ----------------------------------------- section 1 discription and short explanation ---------------------------------------------------- */}
    <div className='whatwedo-dis'>
      <div data-aos="fade-up" className='whatwedo-dis-sec1-1'>
        <div className='wwd-dis-heading'>
          <hr style={{width:'10%', height:'3px', backgroundColor:'#cf7531',marginTop:'5%'}}/>
          <h3 style={{marginLeft:'2%',fontSize:'30px',fontWeight:'600',color:'#cf7531'}}>What We Offer</h3>
        </div>
        <h2 style={{color:'#083b4a',fontWeight:'600',width:'90%',marginTop:'2%'}}>Your Path to Peak Performance Starts Here – Explore the Possibilities</h2>
      </div>
      <div data-aos="fade-up"  className='whatwedo-dis-sec1-2'>
        <p style={{marginTop:'5%',textAlign:'justify'}}>Welcome to Avanza BPO LLC, where our mission is to empower healthcare practices with comprehensive solutions that drive efficiency, excellence., and enhance the overall health of healthcare providers’ business. Led by our founder, Leidy Arguelles, our consulting package is designed to transform and elevate your healthcare business.</p>
      </div>
    </div>
    <hr />
    <div style={{margin:'2% 10% 0 10%'}}>
    <h1 data-aos="fade-up" style={{textAlign:'center',}}>Revenue Cycle Management Solution</h1>
    <p data-aos="fade-up" className='RCM-dsicription' style={{textAlign:'justify',margin:'2% 0 5% 0'}}> AVANZA BPO offer our clients comprehensive Revenue Cycle Management Services by handling all responsibilities internally and taking complete accountability of overall collection for the physician’s groups, private practitioners, and/or Hospitals. Our End to End services includes all administrative and clinical functions that contribute to the capture, management, collection of patient service revenue including handling complex process 24/7 with specialized agents and proprietary technologies to manage healthcare provider revenue cycles. Our successful revenue cycle management relies on measuring key rates and statistics and setting regular goals to measure the efficacy of workflow changes and updates to existing protocols.</p>
    </div>
    
    <div className='demo-rcm-cycle'>
        <div className='demo-rcm-cycle-sec1'>
          <div  className='Beni-verify'>
            <div  className='Beni-verify-but'>
              <h5>Benefits Verification</h5>
            </div>
            <div className='Beni-verify-dis'>
              <h1>Verification of Benefits</h1>
              <p style={{textAlign:'justify'}}>Helps streamline the billing process, prevents claim rejections, and improves patient satisfaction by providing a clear understanding of coverage and financial obligations before care is provided.</p>
            </div>
          </div>
        </div>
        <div className='demo-rcm-cycle-arrow'></div>
        <div className='demo-rcm-cycle-sec2'>
        <div  className='patient-state'>
            <div className='patient-state-but'>
              <h5>Patient Statements and Follow-up</h5>
            </div>
            <div className='patient-state-dis'>
              <h1>Patient Statement and Follow ups</h1>
              <p style={{textAlign:'justify'}}>Patient Statement and Follow ups are essential components of the healthcare revenue cycle, ensuring that patients are informed of their financial responsibilities and facilitating the timely collection of outstanding balances. This process involves generating clear and accurate billing statements and performing regular follow-ups to recover payments, maintain transparency, and improve cash flow.</p>
            </div>
          </div>
          <div className='medicode'>
            <div className='medicode-but'>
              <h5>Medical Coding</h5>
            </div>
            <div className='medicode-dis'>
            <h1>Medical Coding</h1>
            <p style={{textAlign:'justify'}}>Medical Coding is the process of translating healthcare diagnoses, procedures, services, and equipment into standardized codes. These codes are essential for accurately billing insurance companies, maintaining medical records, and ensuring proper reimbursement for healthcare providers.</p>
            </div>
          </div>
        </div>
        <div className='demo-rcm-cycle-arrow'></div>
        <div className='demo-rcm-cycle-sec3'>
        <div className='Ar-manage'>
            <div className='Ar-manage-but'>
              <h5>AR MAnagement and Reporting</h5>
            </div>
            <div className='Ar-manage-dis'>
              <h1>Accounts Receivables Management</h1>
              <p style={{textAlign:'justify'}}>AR Management is an essential aspect of healthcare revenue cycle management, focused on tracking and collecting payments owed by both patients and insurance payers for services provided. Efficient AR management ensures timely payment, reduces outstanding balances, and prioritizes follow-up on claims that have received no response from payers, ultimately improving cash flow for healthcare providers.</p>
            </div>
          </div>
          <div className='rcm-circle'>
            RCM
          </div>
          <div className='Demo-charge'>
            <div className='Demo-charge-but'>
              <h5>Demo and Charge Entry</h5>
            </div>
            <div className='Demo-charge-dis'>
            <h1>Demo and Charge Entry</h1>
              <p style={{textAlign:'justify'}}>Demo and Charge Entry are essential steps in the healthcare revenue cycle that involve capturing patient demographic information and entering charges for medical services. These processes ensure accurate billing and smooth claim submission .</p>
            </div>
          </div>
        </div>
        <div className='demo-rcm-cycle-arrow'></div>
        <div className='demo-rcm-cycle-sec4'>
        <div className='Pay-post'>
            <div className='Pay-post-but'>
              <h5>Payment Posting</h5>
            </div>
            <div className='Pay-post-dis'>
              <h1>Payment Posting</h1>
              <p style={{textAlign:'justify'}}>It is a vital process in the healthcare revenue cycle, involving the recording and reconciliation of payments received from insurance companies, patients, and other payers. This process ensures that the revenue cycle remains accurate, organized, and up-to-date, enabling healthcare providers to monitor cash flow and track account balances. Proper payment posting is crucial for identifying payment discrepancies, underpayments, and the resolution of patient balances.</p>
            </div>
          </div>
          <div className='claim-sub'>
            <div className='claim-sub-but'>
              <h5>Claims Submission</h5>
            </div>
            <div className='claim-sub-dis'>
              <h1>Claim submission</h1>
              <p style={{textAlign:'justify'}}>It is a critical step in the healthcare revenue cycle, involving the process of sending medical claims to insurance companies for reimbursement. Proper and timely claim submission ensures that healthcare providers are paid for the services rendered to patients.</p>
            </div>
          </div>
        </div>
        <div className='demo-rcm-cycle-arrow'></div>
        <div className='demo-rcm-cycle-sec5'>
        <div className='ERA'>
            <div className='ERA-but'>
              <h5>ERA Rejections and Correction</h5>
            </div>
            <div className='ERA-dis'>
              <h1>ERA Rejections and Correction</h1>
              <p style={{textAlign:'justify'}}></p>
            </div>
          </div>
          
        </div>
        <div className='demo-rcm-cycle-arrow'></div>
      </div>
    {/* <div className="RCMCycle-main-session">
    <div className="RCMCycle-left-session">
      <div className='RCM-diagram'>
      <div data-aos="fade-right" className="RCMCycle-session1">
      <Link to="/whatweoffer/Revenue-Cycle-Management/"><button>Benefits Verification</button></Link> 
      </div>
      <div className="RCMCycle-session2">
        <Link to="/whatweoffer/Revenue-Cycle-Management/PatientStatement"><button data-aos="fade-right" >Patient Statements and Follow-Ups</button></Link>
        <Link to="/whatweoffer/Revenue-Cycle-Management/Medical-Coding"><button data-aos="fade-left">Medical Coding</button></Link>
      </div>
      <div className="RCMCycle-session3">
        <Link to="/whatweoffer/Revenue-Cycle-Management/Account-Receivable-Management"><button data-aos="fade-right">AR Management</button></Link>
        <Link to=""><button style={{height:'150px', position:'relative',top:'-40px',borderRadius:'50%',fontWeight:'700',fontSize:'50px'}}>RCM</button></Link>
        <Link to="/whatweoffer/Revenue-Cycle-Management/Demo-and-Charge-Entry"><button data-aos="fade-left">Demo and Charge Entry</button></Link>      </div>
      <div className="RCMCycle-session4">
        <Link to="/whatweoffer/Revenue-Cycle-Management/Report-and-Analytcs"><button data-aos="fade-right">Report And Analytics</button></Link>
        <Link to="/whatweoffer/Revenue-Cycle-Management/Claims-Submission"><button data-aos="fade-left">Claims Submission</button></Link>
      </div>
      <div className="RCMCycle-session5">
        <Link to="/whatweoffer/Revenue-Cycle-Management/Payment-Posting"><button data-aos="fade-right">Payment Posting</button></Link>
        <Link to="/whatweoffer/Revenue-Cycle-Management/Claim-Rejection-and-Correction"><button data-aos="fade-left">Claim Rejection and Correction</button></Link>
      </div>
      </div>
      
    </div>
    <div data-aos="fade-left" className="RCMCycle-right-session" style={{width:'50%'}}>
        <Outlet />
    </div>
    </div> */}
    <div className='Mobile-RCM-content' >
      <div className='offer-content' style={{display:'flex'}}>
        <div className='consulting-left-session'>
          <a href="#Benefits Verification"><button className="consulting-left-session-btn-1">Benefits Verification</button></a>
          <a href="#Medical Coding"><button className="consulting-left-session-btn-2">Medical Coding</button></a>
          <a href="#Demo and charge Entry"><button className="consulting-left-session-btn-3">Demo and charge Entry</button></a>
          <a href="#Claims submission"><button className="consulting-left-session-btn-4">Claims submission</button></a>
          <a href="#Reports and Analytics"><button className="consulting-left-session-btn-4">Reports and Analytics</button></a>
          <a href="#AR Management"><button className="consulting-left-session-btn-4">AR Management</button></a>
          <a href="#Patient Statement"><button className="consulting-left-session-btn-4">Patient Statement and Follow-ups</button></a>  
        </div>
        <div className='consulting-right-session'>
        <h1 id='Benefits Verification' className='consulting-right-session-title' >Revenue Cycle Management Solution</h1>
        <div className='consulting-right-session-content' style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title'>- Benefits Verification</p>
          <p id="Medical Coding" className='consulting-right-session-content-para' style={{margin:'0 0 0 3%'}}>Helps streamline the billing process, prevents claim rejections, and improves patient satisfaction by providing a clear understanding of coverage and financial obligations before care is provided.</p>
        </div>
        <hr />
        <div className='consulting-right-session-content' style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title'>- Medical Coding</p>
          <p id='Demo and charge Entry' className='consulting-right-session-content-para' style={{margin:'0 0 0 3%'}}>Is the process of translating healthcare diagnoses, procedures, services, and equipment into standardized codes. These codes are essential for accurately billing insurance companies, maintaining medical records, and ensuring proper reimbursement for healthcare providers.</p>
        </div>
        <hr  />
        <div className='consulting-right-session-content' style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title'>- Demo and charge Entry</p>
          <p id='Claims submission' className='consulting-right-session-content-para' style={{margin:'0 0 0 3%'}}>Are essential steps in the healthcare revenue cycle that involve capturing patient demographic information and entering charges for medical services. These processes ensure accurate billing and smooth claim submission .</p>

        </div>
        <hr />
        <div className='consulting-right-session-content' style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title'>- Claims submission</p>
          <p id='Reports and Analytics' className='consulting-right-session-content-para' style={{margin:'0 0 0 3%'}}>It is a critical step in the healthcare revenue cycle, involving the process of sending medical claims to insurance companies for reimbursement. Proper and timely claim submission ensures that healthcare providers are paid for the services rendered to patients.</p>
        </div>
        <hr />
        <div className='consulting-right-session-content' style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title'>- Reports and Analytics</p>
          <p id='AR Management' className='consulting-right-session-content-para' style={{margin:'0 0 0 3%'}}>Play's a crucial role in healthcare revenue cycle management by providing actionable insights into financial performance, claim trends, payment collections, and operational efficiency. Utilizing reporting tools and data analysis allows healthcare providers to monitor the health of their billing processes, identify areas for improvement, and make informed decisions to optimize revenue.</p>
        </div>
        <hr />
        <div className='consulting-right-session-content' style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title'>- AR Management</p>
          <p id='Patient Statement' className='consulting-right-session-content-para' style={{margin:'0 0 0 3%'}}>Account Receivable Management is an essential aspect of healthcare revenue cycle management, focused on tracking and collecting payments owed by both patients and insurance payers for services provided. Efficient AR management ensures timely payment, reduces outstanding balances, and prioritizes follow-up on claims that have received no response from payers, ultimately improving cash flow for healthcare providers.</p>
        </div>
        <hr />
        <div className='consulting-right-session-content' style={{padding:'0 0% 5% 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title'>- Patient Statement and Follow-ups</p>
          <p className='consulting-right-session-content-para' style={{margin:'0 0 0 3%'}}>Patient Statement and Follow ups are essential components of the healthcare revenue cycle, ensuring that patients are informed of their financial responsibilities and facilitating the timely collection of outstanding balances. This process involves generating clear and accurate billing statements and performing regular follow-ups to recover payments, maintain transparency, and improve cash flow.</p>
        </div>
        </div>

      </div>
    </div>
    
 
    <hr />
    <div className='whatwedo-dis-sec3'>
      <div className='whatwedo-dis-sec3-1'>
        <div className='wwd-dis-heading'>
          <hr style={{width:'10%', height:'3px', backgroundColor:'#cf7531',marginTop:'5%'}}/>
          <h3 style={{marginLeft:'2%',fontSize:'30px',fontWeight:'600',color:'#cf7531'}}>At Avanza BPO LLC</h3>
        </div>
        <h2 style={{color:'#083b4a',fontWeight:'600',width:'90%',marginTop:'2%'}}>Partner with Us for Excellence in Healthcare Management</h2>
        <p style={{textAlign:'justify'}}>At Avanza BPO LLC, we are committed to delivering solutions that not only meet but exceed your expectations. Partner with us to enhance your practice’s efficiency, compliance, and overall performance. Let’s work together to achieve excellence in healthcare practice management.</p>
      </div>
      <div className='whatwedo-dis-sec3-2'>
        <ContForm />
      </div>
    </div>
    </div>
    
  )
}

export default RCM