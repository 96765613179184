import React from 'react'
import ContForm from '../../contactform-component/ContForm'

function Remittance() {
  return (
    <div>
            <div className='whatwedo-banner'>
        <h1>Remittance Posting</h1>
      </div>
      {/* ----------------------------------------- section 1 discription and short explanation ---------------------------------------------------- */}
    <div className='whatwedo-dis'>
      <div className='whatwedo-dis-sec1-1'>
        <div className='wwd-dis-heading'>
          <hr style={{width:'10%', height:'3px', backgroundColor:'#cf7531',marginTop:'5%'}}/>
          <h3 style={{marginLeft:'2%',fontSize:'30px',fontWeight:'600',color:'#cf7531'}}>What We Offer</h3>
        </div>
        <h2 style={{color:'#083b4a',fontWeight:'600',width:'90%',marginTop:'2%'}}>Your Path to Peak Performance Starts Here – Explore the Possibilities</h2>
      </div>
      <div className='whatwedo-dis-sec1-2'>
        <p style={{marginTop:'5%',textAlign:'justify'}}>Welcome to Avanza BPO LLC, where our mission is to empower healthcare practices with comprehensive solutions that drive efficiency, excellence., and enhance the overall health of healthcare providers’ business. Led by our founder, Leidy Arguelles, our consulting package is designed to transform and elevate your healthcare business.</p>
      </div>
    </div>
   
    <div style={{width:'100%',display:'flex',justifyContent:'center',marginTop:'5%'}}>
      <div className='offer-content' style={{display:'flex'}}>
        <div className='consulting-left-session'>
          <a href="#Data Entry and Verification"><button className="consulting-left-session-btn-1">Data Entry and Verification</button></a>
          <a href="#Payment Posting"><button className="consulting-left-session-btn-2">Payment Posting</button></a>
          <a href="#Adjustment Processing"><button className="consulting-left-session-btn-3">Adjustment Processing</button></a>
          <a href="#Patient Account Updates"><button className="consulting-left-session-btn-4">Patient Account Updates</button></a>
          <a href="#Insurance Coordination"><button className="consulting-left-session-btn-4">Insurance Coordination</button></a>
          <a href="#Customer Support"><button className="consulting-left-session-btn-4">Customer Support</button></a>
          <a href="#Compliance and Security"><button className="consulting-left-session-btn-4">Compliance and Security</button></a>
  
        </div>
        <div className='consulting-right-session'>
        <h1 className='consulting-right-session-title' id='Initial Review of Accounts Receivable' style={{textAlign:'center', margin:'2% 10% 0 10%'}}>Remittance Posting</h1>
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title' style={{color:'#cf7531',fontWeight:'600'}}>- Data Entry and Verification</p>
          <p  className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Accurate entry of payment information from remittance advices.</p>
          <p className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Verification of payment amounts against billed services.</p>
          <p id="Payment Posting" className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Cross-checking data against patient accounts for accuracy.</p>

        </div>
        <hr />
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title' style={{color:'#cf7531',fontWeight:'600'}}>- Payment Posting</p>
          <p className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Posting payments to patient accounts in the billing system.</p>
          <p id='Claim Status Inquiry' className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Documenting all findings and updating patient records accordingly.</p>
        </div>
        <hr  />
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title'style={{color:'#cf7531',fontWeight:'600'}}>- Claim Status Inquiry</p>
          <p className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Checking the status of submitted claims with insurance companies.</p>
          <p  className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Updating insurance payments and adjustments.</p>
          <p id='Adjustment Processing' className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Handling partial payments.</p>
        </div>
        <hr  />
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title'style={{color:'#cf7531',fontWeight:'600'}}>- Adjustment Processing</p>
          <p className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Recording adjustments, write-offs, and discounts.</p>
          <p id='Patient Account Updates'className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Updating accounts with the correct payment status.</p>

        </div>
        <hr />
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title'  style={{color:'#cf7531',fontWeight:'600'}}>- Patient Account Updates</p>
          <p className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Informing patients of their financial responsibilities regarding outstanding balances after insurance payment- Patient statements</p>
          <p id='Updating Patient Records' className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Providing clear explanations of charges, payments, and any remaining amounts due.</p>
        </div>
        <hr />
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title' style={{color:'#cf7531',fontWeight:'600'}}>- Updating Patient Records</p>
          <p className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Updating patient accounts with new payment information.</p>
          <p id='Insurance Coordination' className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Communicating changes to patients as necessary (patient statements)</p>
          <hr />
        </div>
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title' style={{color:'#cf7531',fontWeight:'600'}}>- Insurance Coordination</p>
          <p id='Compliance and Security' className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Ensuring accurate posting of primary and secondary insurance payments.</p>
          <hr />
        </div>
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title' style={{color:'#cf7531',fontWeight:'600'}}>- Compliance and Security</p>
          <p className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Ensuring compliance with healthcare regulations and standards (e.g., HIPAA).</p>
          <p className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Implementing secure data handling and storage practices.</p>

        </div>
        </div>

      </div>
    </div>
    <hr style={{marginTop:'3%'}} />
    <div className='whatwedo-dis-sec3'>
      <div className='whatwedo-dis-sec3-1'>
        <div className='wwd-dis-heading'>
          <hr style={{width:'10%', height:'3px', backgroundColor:'#cf7531',marginTop:'5%'}}/>
          <h3 style={{marginLeft:'2%',fontSize:'30px',fontWeight:'600',color:'#cf7531'}}>At Avanza BPO LLC</h3>
        </div>
        <h2 style={{color:'#083b4a',fontWeight:'600',width:'90%',marginTop:'2%'}}>Partner with Us for Excellence in Healthcare Management</h2>
        <p style={{textAlign:'justify'}}>At Avanza BPO LLC, we are committed to delivering solutions that not only meet but exceed your expectations. Partner with us to enhance your practice’s efficiency, compliance, and overall performance. Let’s work together to achieve excellence in healthcare practice management.</p>
      </div>
      <div className='whatwedo-dis-sec3-2'>
        <ContForm />
      </div>
    </div>
    </div>
  )
}

export default Remittance