import React from 'react'

function PatientBilling() {
  return (
    <div className='carousal-session2-1'>
        <h1>Patient Statement and Follow ups</h1>
        <p>Patient Statement and Follow ups are essential components of the healthcare revenue cycle, ensuring that patients are informed of their financial responsibilities and facilitating the timely collection of outstanding balances. This process involves generating clear and accurate billing statements and performing regular follow-ups to recover payments, maintain transparency, and improve cash flow.</p>
    </div>
  )
}

export default PatientBilling