import React from 'react'
import ContForm from '../../contactform-component/ContForm'

function AccRec() {
  return (
    <div>
    <div className='whatwedo-banner'>
        <h1>Accounts Receivable (AR) Follow-Up</h1>
      </div>
      {/* ----------------------------------------- section 1 discription and short explanation ---------------------------------------------------- */}
    <div className='whatwedo-dis'>
      <div className='whatwedo-dis-sec1-1'>
        <div className='wwd-dis-heading'>
          <hr style={{width:'10%', height:'3px', backgroundColor:'#cf7531',marginTop:'5%'}}/>
          <h3 style={{marginLeft:'2%',fontSize:'30px',fontWeight:'600',color:'#cf7531'}}>What We Offer</h3>
        </div>
        <h2 style={{color:'#083b4a',fontWeight:'600',width:'90%',marginTop:'2%'}}>Your Path to Peak Performance Starts Here – Explore the Possibilities</h2>
      </div>
      <div className='whatwedo-dis-sec1-2'>
        <p style={{marginTop:'5%',textAlign:'justify'}}>Welcome to Avanza BPO LLC, where our mission is to empower healthcare practices with comprehensive solutions that drive efficiency, excellence., and enhance the overall health of healthcare providers’ business. Led by our founder, Leidy Arguelles, our consulting package is designed to transform and elevate your healthcare business.</p>
      </div>
    </div>
    
    <div style={{width:'100%',display:'flex',justifyContent:'center',marginTop:'5%'}}>
      <div className='offer-content' style={{display:'flex'}}>
        <div className='consulting-left-session'>
          <a href="#Initial Review of Accounts Receivable"><button className="consulting-left-session-btn-1">Initial Review of Accounts Receivable</button></a>
          <a href="#Contacting Insurance Companies"><button className="consulting-left-session-btn-2">Contacting Insurance Companies</button></a>
          <a href="#Claim Status Inquiry"><button className="consulting-left-session-btn-3">Claim Status Inquiry</button></a>
          <a href="#Monitoring Payment Timelines"><button className="consulting-left-session-btn-4">Monitoring Payment Timelines</button></a>
          <a href="#Patient Communication"><button className="consulting-left-session-btn-4">Patient Communication</button></a>
          <a href="#Compliance and Documentation"><button className="consulting-left-session-btn-4">Compliance and Documentation</button></a>
          <a href="#Internal communication"><button className="consulting-left-session-btn-4">Internal communication</button></a>
  
        </div>
        <div className='consulting-right-session'>
        <h1 className='consulting-right-session-title' id='Initial Review of Accounts Receivable' style={{textAlign:'center', margin:'2% 10% 0 10%'}}>Accounts Receivable (AR) Follow-Up </h1>
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title' style={{color:'#cf7531',fontWeight:'600'}}>- Initial Review of Accounts Receivable</p>
          <p  className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Conducting a thorough assessment of the AR aging report related to insurance claims.</p>
          <p id="Contacting Insurance Companies" className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Identifying accounts with outstanding balances and prioritizing follow-up actions.</p>
        </div>
        <hr />
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title' style={{color:'#cf7531',fontWeight:'600'}}>- Contacting Insurance Companies</p>
          <p className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Communicating with insurance providers to verify the status of unpaid or underpaid claims.</p>
          <p id='Claim Status Inquiry' className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Documenting all findings and updating patient records accordingly.</p>
        </div>
        <hr  />
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title' style={{color:'#cf7531',fontWeight:'600'}}>- Claim Status Inquiry</p>
          <p className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Checking the status of submitted claims with insurance companies.</p>
          <p id='Monitoring Payment Timelines' className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Documenting all findings and updating patient records accordingly.</p>
        </div>
        <hr  />
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title' style={{color:'#cf7531',fontWeight:'600'}}>- Monitoring Payment Timelines</p>
          <p className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Keeping track of payment timelines set by insurance companies.</p>
          <p id='Patient Communication'className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Sending follow-up reminders for claims that have exceeded standard processing times.</p>

        </div>
        <hr />
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title' style={{color:'#cf7531',fontWeight:'600'}}>- Patient Communication</p>
          <p className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Informing patients of their financial responsibilities regarding outstanding balances after insurance payment- Patient statements</p>
          <p id='Updating Patient Records' className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Providing clear explanations of charges, payments, and any remaining amounts due.</p>
        </div>
        <hr />
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title' style={{color:'#cf7531',fontWeight:'600'}}>- Updating Patient Records</p>
          <p className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Maintaining accurate and up-to-date records of all communications with insurance companies and patients.</p>
          <p id='Compliance and Documentation' className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Reflecting all changes and updates in the billing system.</p>
          <hr />
        </div>
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title' style={{color:'#cf7531',fontWeight:'600'}}>- Compliance and Documentation</p>
          <p className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Ensuring adherence to relevant regulations and internal policies during follow-up with insurance companies.</p>
          <p id='Internal communication' className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Documenting all follow-up activities for auditing and compliance purposes.</p>
          <hr />
        </div>
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title' style={{color:'#cf7531',fontWeight:'600'}}>- Internal communication</p>
          <p className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Evaluating existing AR processes related to insurance claims and providing recommendations for improvement.</p>

        </div>
        </div>

      </div>
    </div>
    <hr style={{marginTop:'3%'}} />
    <div className='whatwedo-dis-sec3'>
      <div className='whatwedo-dis-sec3-1'>
        <div className='wwd-dis-heading'>
          <hr style={{width:'10%', height:'3px', backgroundColor:'#cf7531',marginTop:'5%'}}/>
          <h3 style={{marginLeft:'2%',fontSize:'30px',fontWeight:'600',color:'#cf7531'}}>At Avanza BPO LLC</h3>
        </div>
        <h2 style={{color:'#083b4a',fontWeight:'600',width:'90%',marginTop:'2%'}}>Partner with Us for Excellence in Healthcare Management</h2>
        <p style={{textAlign:'justify'}}>At Avanza BPO LLC, we are committed to delivering solutions that not only meet but exceed your expectations. Partner with us to enhance your practice’s efficiency, compliance, and overall performance. Let’s work together to achieve excellence in healthcare practice management.</p>
      </div>
      <div className='whatwedo-dis-sec3-2'>
        <ContForm />
      </div>
    </div>
    </div>
  )
}

export default AccRec