import React from 'react'
import './Whatwedo.css'
import consultingicon from './wwd-icons/consulting-package.png'
import trainer from './wwd-icons/Trainer.png'
import RCMicon from './wwd-icons/RCM-cycle icon.png'
import medicalCoding from './wwd-icons/medical-coding.png'
import medicalBilling from './wwd-icons/medical-billing.png'
import danielManagement from './wwd-icons/Daniel management.png'
import remittance from './wwd-icons/remittance.png'
import accRecievable from './wwd-icons/Account-recievable.png'
import { Link } from 'react-router-dom';
import ContForm from '../../contactform-component/ContForm';

import { useEffect } from 'react';

function WhatWeDo() {

  useEffect(() => {
    const element = document.getElementById(window.location.hash.substring(1));
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <div>
      <div  className='whatwedo-banner'>
        <h1>What We Offer <br /><span>Transform Your Practice, Maximize Efficiency, and Drive Success</span></h1>
      </div>
      {/* ----------------------------------------- section 1 discription and short explanation ---------------------------------------------------- */}
    <div className='whatwedo-dis'>
      <div className='whatwedo-dis-sec1-1'>
        <div className='wwd-dis-heading'>
          <hr style={{width:'10%', height:'3px', backgroundColor:'#cf7531',marginTop:'5%'}}/>
          <h3 style={{fontWeight:'600',color:'#cf7531'}}>What We Offer</h3>
        </div>
        <h2  style={{color:'#083b4a',fontWeight:'600',width:'90%',marginTop:'2%'}}>Your Path to Peak Performance Starts Here – Explore the Possibilities</h2>
      </div>
      <div className='whatwedo-dis-sec1-2'>
        <p >Welcome to Avanza BPO LLC, where our mission is to empower healthcare practices with comprehensive solutions that drive efficiency, excellence., and enhance the overall health of healthcare providers’ business. Led by our founder, Leidy Arguelles, our consulting package is designed to transform and elevate your healthcare business.</p>
      </div>
    </div>


    <div id="whatweoffer" className='wwd-sec2'>
      <div className="wwd-sec2-1">
      <div style={{display:'flex',justifyContent:'space-evenly', padding:'5%'}}>
          <img className='wwd-card-icon' src={consultingicon} alt="" />
          <h3 >Consulting Package</h3>
        </div>
        <hr />
        <Link to="/whatweoffer/ConsultingPackage" onClick={() => window.scrollTo(0, 0)}><p className='wwd-sec2-1-cont'  >Our Consulting package is developed to optimize your business operations through comprehensive reports, personalized training, valuable resources, and ongoing consultation. It also features a detailed Training Guide, thoughtfully created by Founder Leidy Arguelles.</p>
        <p style={{color:'#243043', fontSize:'15px', textAlign:'end', marginRight:'3%', fontWeight:'600'}}>View more...</p></Link>
      </div>


     <div className="wwd-sec2-2">
        <div style={{display:'flex',justifyContent:'space-evenly', padding:'5%'}}>
          <img className='wwd-card-icon' src={trainer} alt="" />
          <h3 >Personalized Training for Operations Staff</h3>
        </div>
        <hr />
        <Link to="/whatweoffer/PersonalizedTrainning" onClick={() => window.scrollTo(0, 0)}><p className='wwd-sec2-1-cont' >Our Personalized Training for Operations Staff is specifically designed to enhance team efficiency by addressing skill gaps and practice-specific needs. This customized program ensures that your operations team is equipped with the tools and knowledge necessary to improve productivity and performance.</p>
        <p style={{color:'#243043', fontSize:'15px', textAlign:'end', marginRight:'3%', fontWeight:'600'}}>View more...</p></Link>
      </div>


      <div className="wwd-sec2-3">
      <div style={{display:'flex',justifyContent:'space-evenly', padding:'5%'}}>
          <img className='wwd-card-icon' src={medicalCoding} alt="" />
          <h3 >Medical Coding</h3>
        </div>
        <hr />
        <Link to="/whatweoffer/MedicalCoding" onClick={() => window.scrollTo(0, 0)}><p className='wwd-sec2-1-cont' >Our specialized coding services are offered for different medical fields; we ensure precision and reduce errors in billing. Our charge capture process guarantees that all services provided are accurately documented and coded. These elements work together to enhance revenue cycle efficiency, prevent financial loss, and ensure compliance with industry regulations. This approach optimizes the billing process, ensuring that healthcare providers receive appropriate reimbursement while maintaining regulatory adherence.</p>
        <p style={{color:'#243043', fontSize:'15px', textAlign:'end', marginRight:'3%', fontWeight:'600'}}>View more...</p></Link>
      </div>
      
      <div className="wwd-sec2-5">
      <div style={{display:'flex',justifyContent:'space-evenly', padding:'5%'}}>
          <img className='wwd-card-icon' src={medicalBilling} alt="" />
          <h3 >Medical Billing</h3>
        </div>
        <hr />
        <Link to="/whatweoffer/MedicalBilling" onClick={() => window.scrollTo(0, 0)}><p className='wwd-sec2-1-cont' >Our medical billing service is designed to optimize revenue cycle efficiency, minimize delays or denials, and ensure compliance with healthcare regulations, allowing providers to focus on delivering patient care.</p>
        <p style={{color:'#243043', fontSize:'15px', textAlign:'end', marginRight:'3%', fontWeight:'600'}}>View more...</p></Link>

      </div>
      <div className="wwd-sec2-4">
      <div style={{display:'flex',justifyContent:'space-evenly', padding:'5%'}}>
          <img className='wwd-card-icon' src={remittance} alt="" />
          <h3 >Remittance posting</h3>
        </div>
        <hr />
        <Link to="/whatweoffer/Remittance Posting" onClick={() => window.scrollTo(0, 0)}><p className='wwd-sec2-1-cont' >Our remittance posting service enhances cash flow management, improves accuracy in payment tracking, and supports efficient revenue cycle management for healthcare organizations.</p>
        <p style={{color:'#243043', fontSize:'15px', textAlign:'end', marginRight:'3%', fontWeight:'600'}}>View more...</p></Link>

      </div>
      <div className="wwd-sec2-6">
      <div style={{display:'flex',justifyContent:'space-evenly', padding:'5%'}}>
          <img className='wwd-card-icon' src={accRecievable} alt="" />
          <h3 >Accounts Receivable (AR) Follow-Up</h3>
        </div>
        <hr />
        <Link to="/whatweoffer/Accounts Receivable (AR) Follow-Up" onClick={() => window.scrollTo(0, 0)}><p className='wwd-sec2-1-cont' >Our Accounts Receivable (AR) Follow-Up Service is designed to streamline and optimize the collection of outstanding balances related to insurance claims. This service ensures prompt resolution of unpaid or underpaid claims, maintains compliance with regulations, and enhances the efficiency of your revenue cycle.</p>
        <p style={{color:'#243043', fontSize:'15px', textAlign:'end', marginRight:'3%', fontWeight:'600'}}>View more...</p></Link>

      </div>
      <div className="wwd-sec2-7">
      <div style={{display:'flex',justifyContent:'space-evenly', padding:'5%'}}>
          <img className='wwd-card-icon' src={danielManagement} alt="" />
          <h3 >Denial Management Services</h3>
        </div>
        <hr />
        <Link to="/whatweoffer/DanielManage" onClick={() => window.scrollTo(0, 0)}><p className='wwd-sec2-1-cont' >Our Denial Management Services are designed to effectively address and resolve denied insurance claims, reducing the occurrence of future denials and improving the overall revenue cycle process. The service is comprehensive, covering denial analysis, resolution, appeals, communication with payers, and prevention strategies, ensuring compliance with all relevant regulations.</p>
        <p style={{color:'#243043', fontSize:'15px', textAlign:'end', marginRight:'3%', fontWeight:'600'}}>View more...</p></Link>

      </div>
      <div className="wwd-sec2-7">
      <div style={{display:'flex',justifyContent:'space-evenly', padding:'5%'}}>
          <img className='wwd-card-icon' src={RCMicon} alt="" />
          <h3 >Revenue Cycle Management Service</h3>
        </div>
        <hr />
        <Link to="/whatweoffer/Revenue-Cycle-Management" onClick={() => window.scrollTo(0, 0)}><p className='wwd-sec2-1-cont' >Our Denial Management Services are designed to effectively address and resolve denied insurance claims, reducing the occurrence of future denials and improving the overall revenue cycle process. The service is comprehensive, covering denial analysis, resolution, appeals, communication with payers, and prevention strategies, ensuring compliance with all relevant regulations.</p>
        <p style={{color:'#243043', fontSize:'15px', textAlign:'end', marginRight:'3%', fontWeight:'600'}}>View more...</p></Link>

      </div> 
      
    </div>
    <hr style={{margin:'5% 0'}} />
    <div className='whatwedo-dis-sec3'>
      <div className='whatwedo-dis-sec3-1'>
        <div className='wwd-dis-heading'>
          <hr style={{width:'10%', height:'3px', backgroundColor:'#cf7531',marginTop:'5%'}}/>
          <h3 style={{fontWeight:'600',color:'#cf7531'}}>At Avanza BPO LLC</h3>
        </div>
        <h2 style={{color:'#083b4a',fontWeight:'600',width:'90%',marginTop:'2%'}}>Partner with Us for Excellence in Healthcare Management</h2>
        <p style={{textAlign:'justify'}}>At Avanza BPO LLC, we are committed to delivering solutions that not only meet but exceed your expectations. Partner with us to enhance your practice’s efficiency, compliance, and overall performance. Let’s work together to achieve excellence in healthcare practice management.</p>
      </div>
      <div className='whatwedo-dis-sec3-2'>
        <ContForm />
      </div>
    </div>

    </div>
  )
}

export default WhatWeDo