import React from 'react'
import ContForm from '../../contactform-component/ContForm'
import { useEffect } from 'react'

function MedicalCodingPart() {
  useEffect(() => {
    const element = document.getElementById(window.location.hash.substring(1));
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <div>
        <div className='whatwedo-banner'>
        <h1>Medical Coding</h1>
      </div>
      {/* ----------------------------------------- section 1 discription and short explanation ---------------------------------------------------- */}
    <div className='whatwedo-dis'>
      <div className='whatwedo-dis-sec1-1'>
        <div className='wwd-dis-heading'>
          <hr style={{width:'10%', height:'3px', backgroundColor:'#cf7531',marginTop:'5%'}}/>
          <h3 style={{marginLeft:'2%',fontSize:'30px',fontWeight:'600',color:'#cf7531'}}>What We Offer</h3>
        </div>
        <h2 style={{color:'#083b4a',fontWeight:'600',width:'90%',marginTop:'2%'}}>Your Path to Peak Performance Starts Here – Explore the Possibilities</h2>
      </div>
      <div className='whatwedo-dis-sec1-2'>
        <p style={{marginTop:'5%',textAlign:'justify'}}>Welcome to Avanza BPO LLC, where our mission is to empower healthcare practices with comprehensive solutions that drive efficiency, excellence., and enhance the overall health of healthcare providers’ business. Led by our founder, Leidy Arguelles, our consulting package is designed to transform and elevate your healthcare business.</p>
      </div>
    </div>
    
    <div style={{margin:'0 10%'}}>
        <h1 className='consulting-right-session-title'  style={{textAlign:'center',margin:'3% 0'}}>Medical Coding</h1>
        <p className='consulting-right-session-content-para' id='medicalcoding' style={{textAlign:'justify'}}>Avanza BPO’s Medical Coding Services provide precise, compliant coding to maximize reimbursement and streamline the revenue cycle. Our team expertly handles ICD-10, CPT, and HCPCS coding, ensuring accuracy across specialties while maintaining strict compliance with HIPAA standards. Through Charge Capture and proactive Denial Prevention Strategies, we help minimize errors and avoid claim denials. With certified professionals (CPC, CCS, CIC), Avanza BPO’s coding solutions support seamless operations and optimized revenue for healthcare providers.</p>
    </div>


    <div style={{width:'100%',display:'flex',justifyContent:'center',marginTop:'5%'}}>
      <div className='offer-content' style={{display:'flex'}}>
        <div className='consulting-left-session'>
          <a href="#medicalcoding"><button style={{backgroundColor:'#243043', color:'white'}} className="consulting-left-session-btn-1">Medical Coding</button></a>
          <a href="#Codings we use"><button className="consulting-left-session-btn-2">Codings we use</button></a>
          <a href="#Compliance and Regulatory"><button className="consulting-left-session-btn-3">Compliance and Regulatory Support</button></a>
          <a href="#Specialized Coding"><button className="consulting-left-session-btn-4">Specialized Coding Services</button></a>
          <a href="#Charge Capture"><button className="consulting-left-session-btn-1">Charge Capture</button></a>
          <a href="#Relevant Certifications"><button className="consulting-left-session-btn-1">Relevant Certifications</button></a>

        </div>
        <div className='consulting-right-session'>
        <h1 id='Codings we use' style={{textAlign:'center', margin:'2% 10% 0 10%'}}>Medical Coding</h1>
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title'  style={{color:'#cf7531',fontWeight:'600'}}>1. ICD-10 Coding</p>
          <p className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Accurate diagnosis coding using ICD-10-CM.</p>
        </div>
        <hr />
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title'  style={{color:'#cf7531',fontWeight:'600'}}>2. CPT Coding</p>
          <p className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Assignment of CPT codes for procedures and services rendered.</p>
          <p className='consulting-right-session-content-para' id="Appeal Preparation" style={{margin:'3% 0 0 3%'}}>•	Appropriate use of modifiers</p>
        </div>
        <hr />
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title'  id='Compliance and Regulatory' style={{color:'#cf7531',fontWeight:'600'}}>3. HCPCS Coding</p>
          <p className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Coding for non-physician services and durable medical equipment.</p>
        </div>
        <hr />
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title'  style={{color:'#cf7531',fontWeight:'600'}}>- Compliance and Regulatory Support</p>
          <p id='Specialized Coding' className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Guidance on HIPAA compliance and coding accuracy.</p>
        </div>
        <hr />
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title'  style={{color:'#cf7531',fontWeight:'600'}}>- Specialized Coding Services</p>
          <p id='Charge Capture' className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Specialty specific coding</p>
        </div>
        <hr />
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title'  style={{color:'#cf7531',fontWeight:'600'}}>- Charge Capture</p>
          <p className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Accurate recording of services and procedures provided.</p>
          <p id="Relevant Certifications" className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Ensuring that all billable services are documented and coded correctly.</p>
        </div>
        <hr />
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title'  style={{color:'#cf7531',fontWeight:'600'}}>- Denial Prevention Strategies</p>
          <p className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Develop strategies to reduce the occurrence of denials- communicating findings with proper staff member (Front desk Staff, Coder, Biller, Remittance Specialist)</p>
          <p  className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Implement regular audits of coding and billing practices to identify potential issues before claims</p>
        </div>
        <hr />
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title'  style={{color:'#cf7531',fontWeight:'600'}}>- Relevant Certifications</p>
          <p className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>• <b>CPC </b>(Certified Professional Coder)</p>
          <p id='MedicalBilling' className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>• <b>CCS </b>(Certified Coding Specialist) </p>
          <p className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>• <b>CCS-P </b>(Certified Coding Specialist – Physician-based) </p>
          <p className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>• <b>CIC </b>(Certified Inpatient Coder) </p>
          <p className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>• <b>MCS </b>(Medical Coding Specialist) </p>
        </div>
        </div>

      </div>
    </div>
    <hr style={{marginTop:'3%'}} />
    <div className='whatwedo-dis-sec3'>
      <div className='whatwedo-dis-sec3-1'>
        <div className='wwd-dis-heading'>
          <hr style={{width:'10%', height:'3px', backgroundColor:'#cf7531',marginTop:'5%'}}/>
          <h3 style={{marginLeft:'2%',fontSize:'30px',fontWeight:'600',color:'#cf7531'}}>At Avanza BPO LLC</h3>
        </div>
        <h2 style={{color:'#083b4a',fontWeight:'600',width:'90%',marginTop:'2%'}}>Partner with Us for Excellence in Healthcare Management</h2>
        <p style={{textAlign:'justify'}}>At Avanza BPO LLC, we are committed to delivering solutions that not only meet but exceed your expectations. Partner with us to enhance your practice’s efficiency, compliance, and overall performance. Let’s work together to achieve excellence in healthcare practice management.</p>
      </div>
      <div className='whatwedo-dis-sec3-2'>
        <ContForm />
      </div>
    </div>
    </div>
  )
}

export default MedicalCodingPart