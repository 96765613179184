import React from 'react'
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollTop() {
    const { pathname } = useLocation();

    useEffect(() => {
      if (!pathname.includes('Revenue-Cycle-Management')) {
        window.scrollTo(0, 0); // Scroll to the top only if it's not within Revenue Cycle Management paths
      }
    }, [pathname]);
  
    return null;
}

export default ScrollTop