import React from 'react'
import loader1 from './loadergif1.gif'
import loader2 from './loadergif2.gif'
import './load.css'

function Loader() {
  return (
    <div className='loaderpage'>
        <img src={loader2} alt="" />
    </div>
  )
}

export default Loader