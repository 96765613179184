import './Home.css'
import { Link } from 'react-router-dom'
import spl1 from '../cardImages/Operational Optimization - Who We Are Page.jpg'
import spl2 from '../cardImages/Compilance in home page.jpeg'
import spl3 from '../cardImages/Data Driven - Hope Page.jpg'
import cs1 from '../cardImages/Operational Optimization - Home Page.jpg'
import cs2 from '../cardImages/Business Health Improvement - Home Page.jpg'
import cs3 from '../cardImages/Data Driven - Hope Page.jpg'
import ContArea from '../../cont-small component/ContArea'
import { useEffect, useState } from 'react'

function Home() {

  useEffect(() => {
    const element = document.getElementById(window.location.hash.substring(1));
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <div >
      <div className='banner'>
        <div className='bannercontainer' >
          <div className='Block' data-aos="zoom-in-right" >
          <h1 className='Bannertext' >Expert Solutions For Healthcare Success</h1>
          </div>
         
        </div>
      </div>
        {/* <h1 data-aos="fade-in" style={{fontWeight:'600', fontSize:'40px',textAlign:'center', marginTop:'3%'}}>Business Process Outsourcing (BPO) specializing in healthcare</h1> */}
        {/* <hr style={{marginTop:'10px'}}/> */}
        <div className='exp' >
          <div className='exp1'>
            <h1>20 + </h1>
            <p>Year of experience </p>
          </div>
          <div className='exp1'>
          <h1>10 + </h1>
          <p>Specialities served </p>
          </div>
          <div className='exp1' style={{width:'35%',padding:'20px 0 20px 10px'}}>
          <h1>99 % </h1>
          <p>Customer retention</p>
          </div>
        </div>
        <hr style={{backgroundColor:'#083b4a',margin:'0 13% 0 13%'}}/>

        <h1 id='home-heading' style={{color:'#083b4a'}}> <span style={{color:'#367588'}}>Streamline</span> Healthcare Operations with <br /> Advanced <span style={{color:'#367588'}}> Outsourcing Solutions</span></h1>
        <p className='home-heading-para' style={{textAlign:'center',padding:'0 10%',marginTop:'20px'}}>Avanza BPO LLC provides comprehensive outsourcing solutions designed for healthcare providers, featuring expert consulting services and personalized training. Our approach focuses on enhancing operational efficiency, ensuring compliance, and improving overall business health, supported by our extensive industry expertise.</p>


        <div className='home_cards' >
          <div data-aos="fade-right" className='home_card_1' >
          <div data-aos="fade-up" className='home_card_2_name' >
              <p className='home_card_2_name_title' style={{marginTop:'10%'}}>Who We Are</p>
              <a href="/whoweare"><p style={{margin:'15px', color:'white'}}>Avanza BPO LLC is a premier practice solutions firm recognized for its profound expertise in the medical field. With over 20 years of dedicated experience, we excel in providing top-tier services that encompass both front-end and back-end practice operations.</p></a> 
            </div>
          </div>
          <div  data-aos="fade-up" className='home_card_2'>
            <div data-aos="fade-up" className='home_card_2_name' >
              <p className='home_card_2_name_title'>Custom made services</p>
              <a href="/whatweoffer"><p style={{margin:'15px', color:'white'}}>Our services are tailored to our clients' specific needs and expectations, making it easier for them to select the services they require...</p></a> 
            </div>
          </div>
          <div  data-aos="fade-left" className='home_card_3'>
          <div data-aos="fade-up" className='home_card_2_name' >
              <p className='home_card_2_name_title' style={{marginTop:'10%'}}>Specialities</p>
              <a href="/specialities"><p style={{margin:'15px', color:'white'}}>Our end-to-end services include all administrative and clinical functions that contribute to the capture, management, and distribution of data....</p></a> 
            </div>

          </div>
        </div>


        <div className='homeBanner2' >
        <h1 data-aos="fade-in" >Business Process Outsourcing (BPO) <br /> specializing in healthcare</h1>
        </div>
        <h1 className='homeBanner2-heading' style={{color:'#083b4a'}}>Why Choose Avanza BPO LLC?</h1>
        {/* -------------------------------------------------------------------------------------------------- */}
        {/* ---------------------------------------------------------------------------------------- */}
        <div className='splcontainer' >
          <div data-aos="fade-right" className='splcontainer1'>
            <img className='splimg' src={spl1} alt="" />
            <div className='splcont'>
              <p style={{textAlign:'center',fontSize:'25px',fontWeight:'600'}}>Customized Healthcare BPO Services</p>
              <p style={{textAlign:'justify',fontSize:'13px'}}>We understand that every healthcare provider is unique. That's why we offer customized solutions to address your specific operational challenges, whether it's streamlining workflows, managing resources, or improving business performance.</p>
              <div className='splbtn'>
              <Link to="/whoweare" ><button onClick={() => window.scrollTo(0, 0)}>View more </button></Link>
              </div>
            </div>
          </div>
          <div data-aos="fade-down" className='splcontainer2'>
            <img  className='splimg' src={spl2} alt="" />
            <div className='splcont'>
              <p className='splcont-title' style={{textAlign:'center',fontSize:'25px',fontWeight:'600'}}>100% Compliance Guarantee</p>
              <p style={{textAlign:'justify',fontSize:'13px',marginBottom:'28px'}}>We understand the critical importance of compliance in the healthcare sector. Our 100% Compliance Guarantee underscores our commitment to maintaining the highest standards in every aspect of our service.</p>
              <div className='splbtn'>
              <Link to="/whoweare" ><button onClick={() => window.scrollTo(0, 0)}>View more </button></Link>
              </div>
            </div>
          </div>
          <div data-aos="fade-left" className='splcontainer3'>
            <img className='splimg' src={spl3} alt="" />
            <div className='splcont'>
              <p style={{textAlign:'center',fontSize:'25px',fontWeight:'600'}}>Data-Driven Business Growth</p>
              <p style={{textAlign:'justify',fontSize:'13px'}}>Unlock new potential for your healthcare business with our strategic planning and financial analysis services. Avanza BPO LLC is dedicated to helping you achieve measurable improvements in business performance with a projected 20% increase in efficiency.</p>
              <div className='splbtn'>
              <Link to="/whoweare" ><button style={{marginTop:'20px'}} onClick={() => window.scrollTo(0, 0)}>View more</button></Link>
              </div>
            </div>
          </div>
        </div>
        {/* ----------------------------------------------------------------------------------------------------------------- */}
        <div className='Whoweserve'>
          <h1 >Who We Serve</h1>
          <div className='Wwbut'>
            <Link style={{width:'18%'}} to="/whatwedo"><button  data-aos="zoom-in-up">Hospitals and Health Systems</button></Link>
            <Link style={{width:'18%'}} to="/whatwedo"><button  data-aos="zoom-in-up">Private Medical Practices</button></Link>
            <Link style={{width:'18%'}} to="/whatwedo"><button data-aos="zoom-in-up">Long-Term Care Facilities</button></Link>
            <Link style={{width:'18%'}}to="/whatwedo"><button data-aos="zoom-in-up">Ambulatory Surgical Centers</button></Link>
            <Link style={{width:'18%'}}to="/whatwedo"><button data-aos="zoom-in-up">Physical and Occupational Therapy Centers</button></Link>
          </div>
        </div>
        {/* --------------------------------------------------------------------------------------------------------------------------------- */}
        <h1 className='coreservices-title' >Our Core Services</h1>
        <div className='coreservices'>
          <div data-aos="fade-right" className="cs1">
            <div className='cs1-item1'>
              <img src={cs1} alt="" />
            </div>
            <div className='contcs1'>
              <h1>Operational Optimization</h1>
              <p>We focus on boosting efficiency through workflow redesign, process automation, and resource management. Our solutions are designed to improve client operational performance by 20% in the first year.</p>
               <Link to="/specialities"><button>View details</button></Link>
              <hr />
            </div>
          </div>
          <div data-aos="fade-left" className="cs2">
          <div className='contcs2'>
              <h1>Business Health Improvement</h1>
              <p>We help healthcare providers grow their businesses with data analytics, financial performance reviews, and strategic planning, contributing to a 20% improvement in overall business health.</p>
               <Link to="/specialities"><button>View details</button></Link>
              <hr style={{width:'100%'}} />
          </div>
          <div className='contcs2-1'>
              <img src={cs2} alt="" />
          </div>
          </div>
        </div>
        <div>
          
        </div>
        <ContArea />
    </div>
  )
}

export default Home