import React from 'react'
import './conatactus.css'
import soln from '../cardImages/solution-contactus.png'
import whatsapp from '../icons/whatsapp.png'
import ContForm from '../../contactform-component/ContForm'
import skype from '../icons/skype.png'
import { useEffect } from 'react'
import { RiWhatsappFill } from "react-icons/ri";
import { SiGmail } from "react-icons/si";
import { ImSkype } from "react-icons/im";
import { IoCall } from "react-icons/io5";
import { AiFillInstagram } from "react-icons/ai";
import insta from '../icons/instagram.png'

function ContactUs() {

  useEffect(() => {
    const element = document.getElementById(window.location.hash.substring(1));
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);



  
  return (
    <div>
      <div className='contactus-banner'>
        <div data-aos="fade-in" className='contactus-banner-content'>
          <div style={{display:'flex',justifyContent:'center'}}>
          <h1>Contact us </h1>
          </div>
          <div style={{display:'flex',justifyContent:'center'}}>
          <h3>We’re here to help your healthcare practice thrive.</h3>
          </div>
        <div className='contactus-banner-num-mail' >
        <p style={{margin:'1% 0 0 0%'}}><IoCall style={{marginRight:'10px',fill:'white'}}/><a style={{color:'white'}} href="tel:13057732239">305-773-2239</a></p>
        <p style={{margin:'0 0 0 0%',color:'white',}}>|</p>
        <p style={{margin:'1% 0 0 0%'}}><SiGmail style={{marginRight:'10px',fill:'white'}}/><a style={{color:'white'}} href="/contactus">customerservice@avanzasbpo.com</a></p>
        </div>
        <div className='contact-icons' >
          <a href="#formposition"> <IoCall size={30} style={{ fill: 'white'}}/></a>
          <a href="#formposition"><SiGmail size={30} style={{ fill: 'white'}}/></a>
        <a href="https://wa.me/13057732239">< RiWhatsappFill size={30} style={{ fill: 'white'}}/></a> 
        <a href="https://join.skype.com/invite/w58oOPya8UQ4">< ImSkype size={30} style={{ fill: 'white'}}/></a>
        <a href="https://www.instagram.com/avanzabpo_healthcare_services/profilecard/?igsh=djlwY2hvcXZyNWM4"><AiFillInstagram size={30} style={{ fill: 'white'}} /></a> 
        </div>
        </div> 
      </div>


      <div data-aos="zoom-in" className='title-portion'>
        <div className='title-portion-1'>
        <h1>Let’s Connect and Elevate Your Healthcare Practice</h1>
        <h3>Reach out to us today for expert solutions tailored to your needs.</h3>
        <p>We're here to help streamline your operations, ensure compliance, and enhance your practice’s performance. Whether you have questions or want to learn more about how we can transform your healthcare business, our team is ready to assist. <br />
This content creates a warm and approachable tone while emphasizing the value Avanza BPO LLC offers, encouraging users to make contact.</p>
        </div>
      </div>

      <div className='portion0'>
        <div data-aos="fade-right" className='portion0-1'>
          <div style={{display:'flex',justifyContent:'start'}}>
          <hr style={{width:'60px',backgroundColor:'#cf7531',height:'3px',margin:'3% 0 0 2%'}}/>
          <h3 style={{marginLeft:'2%'}}>Let’s Talk Solutions</h3>
          </div>
          <p style={{margin:'2% '}}>Whether you have questions, need more information, or want to discuss how we can optimize your practice, we’re ready to listen. Fill out the form below, and let’s start a conversation about taking your healthcare practice to the next level. </p>
        </div>
        <div data-aos="fade-left" className='portion0-2'>
          <img src={soln} alt="" />
        </div>
      </div>
      <hr id='formposition' style={{margin:'10% 0 5% 0'}} />
      <div className='portion1'>
        <div data-aos="fade-right" className='portion1-1'>
          <div className='address-sec'>
          <h1>Our Address</h1>
            <div className='address'>
            <p style={{fontSize:'20px', fontWeight:'600'}}>USA</p>
            <p style={{fontSize:'20px', fontWeight:'600'}}>Avanza BPO LLC</p>
            <p>601 Brickell Key Drive, Suite700 Miami Fl 33131 </p>
            <p style={{fontSize:'20px', fontWeight:'600'}}>INDIA</p>
            <p>Keelkattalai, Chennai - 600 117</p>
            <p style={{margin:'3% 0 1% 0%'}}><IoCall style={{marginRight:'10px'}}/>305-773-2239 </p>
            <p style={{margin:'3% 2% 1% 0%'}}><SiGmail style={{marginRight:'10px'}}/>customerservice@avanzasbpo.com</p>

            </div>
            <h1 style={{marginTop:'5%'}}>Why Reach Out?</h1>
            <div className='why-reach-out'>
            <p><span>Tailored Solutions:</span>  Our expert team offers personalized strategies designed specifically for healthcare practices.</p>
            <p><span>Fast Response:</span> Expect a reply from our team within 24 hours to help with any inquiries or support needs.</p>
            <p><span>Free Consultation:</span> Get a complimentary first consultation to assess how we can improve your practice.</p>
            </div>
            <h1 style={{marginTop:'3%'}}>Follow us :</h1>
            <div className='follow-us' style={{height:'100px',display:'flex',justifyContent:'space-around',width:'30%',margin:'3% 0 0 0%'}}>
              <a href="https://wa.me/13057732239" style={{margin:'0% 0 0 2%'}}><img src={whatsapp} alt="" /></a>
              <a href="skype:live:.cid.208e1bfece91a435?chat" style={{margin:'0% 0 0 2%'}}><img src={skype} alt="" /></a>
              <a href="https://www.instagram.com/avanzabpo_healthcare_services/profilecard/?igsh=djlwY2hvcXZyNWM4" style={{margin:'0% 0 0 2%'}}><img src={insta} alt="" /></a>
            </div>
          </div>
        </div>
       
        <div className='portion1-22'>
         <ContForm />
        </div>
      </div>
    </div>
  )
}

export default ContactUs